.header-part {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    // z-index: 5;
    height: $headerHeight + px;
    background: linear-gradient(to bottom, #3A5163 0%, #5b7183 100%);
    background-color: #ffffff;
    .header-title-part {
        padding: 15px 0 0 33px;
        .title-part {
            font-size: 26px;
            font-family: Arial, Helvetica, sans-serif;
            /* line-height: ($headerHeight - 2) + px; */
            font-weight: normal;
            color: #fff;
        }
        .title-sub-part {
            font-size: 18px;
            padding: 5px 0 0 0;
            font-family: Arial, Helvetica, sans-serif;
            color: #fff;
        }
    }
    .logos-part {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: row;
        .header-logo{
            background-repeat: no-repeat;
            background-position: center;
            width: 95px;
            height: 60px;
            margin-left: 10px;
        }
        .header-logo-1{
            background-image: url('');
        }
        .header-logo-2{
            background-image: url('');
        }
        .header-logo-3{
            background-image: url('');
        }
        .header-logo-4{
            background-image: url('');
        }
        .header-logo-5{
            background-image: url('images/oqaasileriffik_logo_60x95.png');
        }
        .header-logo-6{
            background-image: url('');
        }
    }
    .buttons-menu {
        display: none;
    }
    .buttons-part {
        position: absolute;
        background-color: #C35D42;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 1px 0;
        .header-btn {
            cursor: pointer;
            text-decoration: none;
            color: #ffffff;
            font-size: 14px;
            &:hover{
                background-color:#f0f0f0;
                color:#2e2e2e;
            }
        }
        .about-btn {
            padding: 5px 10px;
            .btn-label {
                margin-left:5px;
            }
        }
        .locale-btn {
            width: 40px;
            height: 22px;
            text-align: center;
            padding: 5px 0;
            &:hover img{
                box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.381);
            }
            img {
                height: 20px;
                width: 20px;
            }
        }
        a {
            text-decoration: none;
            // color: #ffffff;
        }
    }
    .version-section {
        position: absolute;
        top: 70px;
        right: 16px;
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }
    &.mobile-header {
        height: $mobileHeaderHeight + px;
        .header-title-part {
            padding: 10px 35px 0 10px;
            .title-part {
                font-size: 22px;
                font-family: Arial, Helvetica, sans-serif;
                /* line-height: ($headerHeight - 2) + px; */
                font-weight: normal;
                color: #fff;
            }
            .title-sub-part {
                font-size: 16px;
            }
        }
        .buttons-menu {
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 18px;
            cursor: pointer;
            color: #ffffff;
            padding: 10px;
            &:hover{
                background-color:#f0f0f0;
                color:#2e2e2e;
            }
        }
        .buttons-part {
            display: none;
        }
        .version-section,
        .logos-part {
            display: none;
        }
        &.mobile-menu-open {
            height: 100%;
            position: fixed;
            z-index: 1;
            .buttons-part {
                top: $mobileHeaderHeight + px;
                left: 0;
                right: 0;
                display: block;
                background-color: #ffffff;
                .header-btn{
                    font-size: 20px;
                    color: #000000;
                    padding: 20px 0px;
                    text-align: center;
                    border-bottom: 1px solid #B7B7B7;
                    width: 100%;
                }
            }
        }
    }
}
