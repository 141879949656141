.hidden{
    display: none;
}
.about-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    .content-overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
    }
    .content-frame {
        margin: auto;
        padding: 20px;
        background: #fff;
        width: 90%;
        height: 90%;
        min-width: 300px;
        min-height: 300px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .page-content {
            position: absolute;
            overflow: auto;
            top: 50px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            .content{
                height: 90%;
            }
        }
    }
    &.positioned {
        left: auto;
        top: $headerHeight + px;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        .content-frame {
            width: $contentWidth + px;
            height: auto;
            left: auto;
            border-left: 1px solid #E9E9E9;
        }
        .content-overlay {
            background: none;
        }
    }
    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    h1 {
        font-size: 1.5rem
    }

}
