$desktopHeight: $headerHeight + px;
$mobileHeight: $mobileHeaderHeight + px;
.mapDiv {
    padding: 0;
    margin: 0;
    height: calc(100% - $desktopHeight);
    width: 100%;
    &.mobile {
      height: calc(100% - $mobileHeight);
    }
  }
  .esri-component.esri-search.esri-widget {
    left: 60px;
    top: 15px;
  }
  .esri-widget .esri-search__container {
    border: 1px solid #E9E9E9;
  }
  
  // overwrite esri's basemap widget max height for small mobile
  .mapDiv.mobile.esri-view .esri-expand div.esri-basemap-gallery {
    max-height: 100%;
  }
  